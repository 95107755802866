@import "./../../index.scss";

.CardStatusDashboard {
  .card {
    width: 100%;
    border-radius: 8px;
    border: 0;

    .card-title {
      text-align: left;
      padding-left: 10px;
      margin-top: 15px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
    }

    .card-body {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 65px;
      line-height: 88px;
      color: #171120;
    }
  }
}
