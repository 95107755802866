@import "./../../index.scss";

.ModalEditClient {
  .modal,
  .modal-body,
  .modal-footer,
  .modal-header,
  .modal-content,
  p {
    background-color: var(white);
    color: var(black) !important;
  }
  h1,
  input {
    color: var(black);
  }

  .btn-primary {
    width: 100%;
  }

  .form-text {
    color: black;
    text-align: center !important;
    width: 100% !important;
    margin: 0 !important;
  }
}
