@import "./../../index.scss";

.Navbar {
  background-color: #fff;

  .dropdown {
    button {
      background: #f4f4f4 !important;
      border-radius: 5px;
      border: 0 !important;
      border-radius: 5px 5px 0 0;
      width: 184px !important;
    }

    .dropdown-menu {
      background: #f4f4f4 !important;
      border: 0 !important;
      border-radius: 0 0 5px 5px;
      margin: 0;
      width: 184px;

      .dropdown-item {
        font-family: "Roboto";

        font-style: normal;
        word-wrap: break-word;
        white-space: normal;
        color: #171120;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
      }

      .dropdown-item:active {
        background-color: $btnPrimary;
      }
    }
  }
}
