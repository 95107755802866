@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);

$bgPrimary: #bbdf84;
$bgSecondary: #fff;

$btnPrimary: #99da35;
$btnDanger: #f78787;

html,
#root {
  background-color: $bgPrimary;
  font-family: "Roboto", sans-serif;
}

.btn-danger {
  box-sizing: border-box;
  // width: 100%;
  background: $btnDanger;
  border: 1px solid #f78787;
  border-radius: 4px;
  color: white !important;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.btn-danger:hover {
  background: $btnDanger !important;
  opacity: 0.8;
}

.btn-primary {
  box-sizing: border-box;
  // width: 100%;
  background: $btnPrimary;
  border: 1px solid #bbdf84;
  border-radius: 4px;
  color: black !important;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.btn-primary:hover {
  background: $btnPrimary !important;
  opacity: 0.8;
}

.btn-primary:disabled {
  background: $btnPrimary !important;
  opacity: 0.6;
  border: 1px solid #bbdf84;
}

.btn-primary:hover {
  border: 1px solid #fff !important;
}

.btn-secondary {
  box-sizing: border-box;
  // width: 100%;
  background: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  color: black;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.form-label {
  margin-bottom: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
