@import "./../../index.scss";

html,
#root {
  background-color: $bgPrimary;
}

.row > * {
  padding: 0;
}

.CardStatusDashboard .card-title {
  text-overflow: ellipsis !important;
}

input.search,
input.filter {
  background: #ffffff;
  border: 1px solid rgba(23, 17, 32, 0.3);
  border-radius: 12px;
  width: 100%;
  height: 40px;
  padding-left: 10px;
}

button.search,
button.filter {
  border-radius: 8px;
}

.btn-secondary.filter {
  // width: 100%;
}