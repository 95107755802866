@import "./../../index.scss";

.VideoTranscription {
  .row {
    padding: 0;
    margin: 0;
  }

  .container-words{
    display: flex;
    flex-flow: wrap;
    .word-search {
      padding: 0.3rem;
      background-color: #fff;
      border-radius: 10px;
    }
    .word-search-group {
      padding: 0.3rem;
      background-color: #2080ff;
      color: white;
      border-radius: 10px;
    }
  }

  .container-words-group{
    display: flex;
    flex-flow: wrap;
    .word-search {
      padding: 0.3rem;
      background-color: #2080ff;
      color: white;
      border-radius: 10px;
    }
  }
}
