@import "./../../index.scss";

.Login {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;

  .column:nth-child(2) {
    background-color: $bgSecondary;
    border-radius: 20px;
    padding: 3rem;

    h1 {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    p {
      text-align: center;
    }

    label {
      font-weight: bold;
    }
  }

  .btn-primary {
    width: 100%;
  }

  .form-text {
    color: black;
    text-align: center !important;
    width: 100% !important;
    margin: 0 !important;
  }
}
