.ModalFilter {
	.modal,
	.modal-body,
	.modal-footer,
	.modal-header,
	.modal-content,
	p {
		background-color: var(white);
        color: var(black) !important;
	}
	h1, input {
		color: var(black);
	}
}
