@import "./../../index.scss";

.PanelMaster {
  .title {
    color: #171120;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
  }

  margin-bottom: 20px;
}
